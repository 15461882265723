import React from "react";
import get from "lodash/get";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout/Layout";
import Section from "../components/layout/Section";
import Header from "../components/layout/Header";
import NewsSiteList from "../components/news-site/NewsSiteList";
import SelectNewsSite from "../components/news-site/SelectNewsSite";

const StyledHeader = styled(Header)`
  margin-bottom: 4rem;
`;

const Page = ({ data }) => {
  const newsSites = get(data, "allnarratives.newsSites");
  const filteredNewsSites = newsSites.filter(n => Boolean(n.slug));
  return (
    <Layout>
      <Section hero>
        <StyledHeader invert />
        <h1>Should I trust media?</h1>
        <p>
          Check background information on your favorite news source. Select from
          the names bellow and find out for yourself.
        </p>
        <SelectNewsSite newsSites={filteredNewsSites} />
      </Section>

      <Section transparent>
        <h1>Why we exist</h1>
        <p>
          Media is a very complicated web of relations and agendas. This website
          was built to gather and connect the publicly available data regarding
          the media. Our vision is to put information together in hopes that it
          will be used for investigative and educative purposes.
        </p>
      </Section>
      <Section>
        <h1>{filteredNewsSites.length} analyzed news sites</h1>
        <NewsSiteList newsSites={filteredNewsSites} />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allnarratives {
      newsSites {
        uuid
        name
        slug
      }
    }
  }
`;

export default Page;
